<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-1">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="4">
            <SelectInventory @onChangeInventory="handleChangeInventory" />
          </b-col>
          <b-col cols="12" md="2"></b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Tìm kiếm...."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          sticky="100%"
          ref="refProductsTable"
          class="position-relative"
          :items="fetchPharmaProducts"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="Không tìm thấy kết quả nào"
          @row-clicked="handleViewDetail"
        >
          <!-- Column: PersonBirthday -->
          <template #cell(prescription_code)="data">
            <span class="text-capitalize">{{`#${data.item.prescription_code}` }}</span>
          </template>
          <!-- Column: PersonBirthday -->
          <template #cell(birthday)="data">
            <span class="text-capitalize">{{ formatYear(data.item.person_info) }}</span>
          </template>
          <template #cell(address)="data">
            <span class="text-capitalize">{{ getAddress(data.item) }}</span>
          </template>
          <!-- Column: CreatedAt -->
          <template #cell(date)="data">
            <span class="text-capitalize">{{ formatDate(data.item.created_at) }}</span>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Hiển thị {{ dataMeta.from }} đến {{ dataMeta.to }} của {{ dataMeta.of }}
              mục
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- Footer bar-->
    <!-- <card-statistics-group />
    <card-product-inventory-remain />-->
  </div>
</template>
  
  <script>
import moment from "moment";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useProductsList from "./prescriptionWaiting";
import SelectInventory from "@/components/SelectInventory/index.vue";

export default {
  name: "Home",
  components: {
    SelectInventory
  },
  computed: {},
  created() {},
  methods: {
    formaterCurrencyToNumber(value) {
      return Number(value.replace(/[^0-9.-]+/g, ""));
    },
    formatDate(time) {
      return moment(time).format("HH:mm:ss DD/MM/YYYY");
    },
    formatYear(time) {
      return time?.birthday ? moment(time?.birthday).format("YYYY") : "";
    },
    handleViewDetail(item) {
      this.$router.replace({
        name: "PrescriptionWaiting",
        params: { id: item.id }
      });
    },
    getAddress(data) {
      let result = "";
      if (data.person_info?.address) {
        result += data.person_info?.address;
      }
      if (data.person_info?.address_district_name) {
        result = result + " " + data.person_info?.address_district_name;
      }
      if (data.person_info?.address_province_name) {
        result = result + " " + data.person_info?.address_province_name;
      }

      return result;
    },
    handleChangeInventory(data) {
      this.inventoryInfo = data;
      this.refetchData();
    }
  },

  setup() {
    const dataTag = ref({});
    const {
      timeout,
      showTable,
      fetchPharmaProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductsTable,
      refetchData,
      productBrands,
      inventoryInfo,
      inventories
    } = useProductsList();
    return {
      timeout,
      showTable,
      // Sidebar
      dataTag,
      fetchPharmaProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductsTable,
      refetchData,
      productBrands,
      inventoryInfo,
      inventories,

      // Filter
      avatarText

      // Extra Filters
    };
  }
};
</script>
  
  <style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  