<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-6">
            <SelectInventory @onChangeInventory="handleChangeInventory" />
          </div>

          <div class="col-md-6">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Tìm kiếm..."
              @update="handleDebounce"
            />
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end col-md-4">
        <b-button
          @click="handleAddBill"
          variant="primary"
          class="mb-2 mr-1"
        >{{type===0?'Thêm phiếu nhập':'Thêm phiếu xuất'}}</b-button>
      </div>
    </div>
    <div v-if="type===1" class="row mb-2">
      <div class="col-md-4">
        <v-select
          placeholder="Trạng thái thanh toán"
          v-model="payment_status"
          label="label"
          :filterable="false"
          :options="statusOptions"
          :reduce="option=>option.value"
          @input="handleChangeStatus"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <feather-icon icon="ChevronDownIcon" size="20" />
            </span>
          </template>
        </v-select>
      </div>
      <div class="col-md-4">
        <v-select
          placeholder="Trạng thái xuất kho"
          v-model="status"
          label="label"
          :filterable="false"
          :options="paymentOptions"
          :reduce="option=>option.value"
          @input="handleChangePaymentStatus"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <feather-icon icon="ChevronDownIcon" size="20" />
            </span>
          </template>
        </v-select>
      </div>
    </div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <b-table
        ref="refProductsTable"
        class="position-relative"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Không có dữ liệu hiển thị"
        tbody-class="cs-table cursor-pointer"
        @row-clicked="handleViewDetail"
      >
        <!-- <template #cell(receiving_code)="data">
          <span class="text-capitalize">{{ `#${(data.item.receiving_code)}` }}</span>
        </template>
        <template #cell(pharma_sale_code)="data">
          <span
            v-if="data.item.pharma_sale_code"
            class="text-capitalize"
          >{{ `#${(data.item.pharma_sale_code)}` }}</span>
        </template>-->
        <template #cell(date_add)="data">
          <span class="text-capitalize">{{ formatDateTime(data.item.date_add) }}</span>
        </template>
        <template #cell(created_at)="data">
          <span class="text-capitalize">{{ formatDateTime(data.item.created_at) }}</span>
        </template>
        <template #cell(sale_type)="data">
          <span class="text-capitalize">{{ getSaleLabel(data.item.sale_type) }}</span>
        </template>
        <template #cell(day_contract)="data">
          <span class="text-capitalize">{{ formatDate(data.item.day_contract) }}</span>
        </template>
        <template #cell(supplier)="data">
          <span class="text-capitalize">{{getSupplierLabel(data.item)}}</span>
        </template>
        <template #cell(price_out)="data">
          <span class="text-capitalize">{{formatNumber(data.item.price_out)}}</span>
        </template>
        <template #cell(expired_date)="data">
          <span class="text-capitalize">{{formatExpiredDate(data.item) }}</span>
        </template>
        <template #cell(receiving_type)="data">
          <span
            class="text-capitalize"
          >{{data.item.receiving_type===2?"Nhập Tồn đầu kỳ":"Nhập Kho" }}</span>
        </template>
      </b-table>
    </b-overlay>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">
            Hiển thị {{ dataMeta.from }} đến {{ dataMeta.to }} của {{ dataMeta.of }}
            mục
          </span>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProducts"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { SALE_TYPE } from "@/utils/constant";
import appUtils from "../../utils/appUtils";
import SelectInventory from "@/components/SelectInventory/index.vue";
import vSelect from "vue-select";

const statusOptions = [
  {
    label: "Chưa thanh toán",
    value: 1
  },
  {
    label: "Đã thanh toán",
    value: 2
  },
  {
    label: "Đã huỷ",
    value: 3
  }
];

const paymentOptions = [
  {
    label: "Chưa xuất kho",
    value: 1
  },
  {
    label: "Đã xuất kho",
    value: 2
  }
];

const tableSalingColumns = [
  { key: "pharma_sale_code", label: "Mã phiếu", sortable: false },
  {
    key: "prescription_info.prescription_code",
    label: "Mã đơn thuốc",
    sortable: false
  },
  {
    key: "prescription_info.person_info.pid",
    label: "Mã Khách hàng",
    sortable: false
  },
  {
    key: "customer_name",
    label: "Tên Khách hàng",
    sortable: false
  },
  {
    key: "prescription_info.prescribed_doctor",
    label: "Bác sĩ kê đơn",
    sortable: false
  },
  {
    key: "prescribed_clinic",
    label: "Nơi chỉ định",
    sortable: false
  },
  { key: "sale_type", label: "Loại phiếu", sortable: false },
  // { key: "status", label: "Trạng thái", sortable: false },
  {
    key: "created_at",
    label: "Ngày tạo",
    sortable: false
  }
];

const tableReceivingColumns = [
  { key: "receiving_code", label: "Mã phiếu", sortable: false },
  { key: "receiving_type", label: "Loại phiếu", sortable: false },
  { key: "supplier", label: "Nhà phân phối", sortable: false },
  { key: "invoice_code", label: "Số Hoá đơn", sortable: false },
  { key: "day_contract", label: "Ngày Hợp đồng", sortable: false },
  { key: "receiver_name", label: "Người nhận hàng", sortable: false },
  {
    key: "date_add",
    label: "Ngày tạo",
    sortable: false
  }
  //   { key: "status", label: "Trạng thái", sortable: false }
];

export default {
  name: "BillTable",
  props: {
    type: Number
  },
  components: { SelectInventory, vSelect },
  data() {
    return {
      isLoading: false,
      dataList: [],
      currentPage: 1,
      perPage: 15,
      totalProducts: 0,
      searchQuery: "",
      inventories: [],
      inventoryInfo: null,
      statusOptions,
      paymentOptions,
      status: null,
      payment_status: null
    };
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.dataList?.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalProducts
      };
    },
    tableColumns() {
      return this.type === 0 ? tableReceivingColumns : tableSalingColumns;
    }
  },
  watch: {
    currentPage() {
      this.handleGetListData();
    },
    $route() {
      this.handleCheckStatus();
    }
  },
  mounted() {
    this.handleCheckStatus();
  },
  methods: {
    handleDebounce: appUtils.debounce(function() {
      this.handleGetListData();
    }, 1000),
    handleViewDetail(item) {
      if (!item?.id) return;
      const routerName = this.type === 0 ? "ReceivingDetail" : "SalingDetail";
      this.$router.push({
        name: routerName,
        params: {
          id: item.id
        }
      });
    },
    handleAddBill() {
      const routerName = this.type === 0 ? "AddReceiving" : "AddSaling";
      this.$router.push({ name: routerName });
    },
    handleGetListData() {
      this.type === 0
        ? this.handleGetReceivingList()
        : this.handleGetSalingList();
    },
    async handleGetReceivingList() {
      try {
        const params = {
          inventory_id: this.inventoryInfo?.id,
          page_num: this.currentPage,
          page_size: this.perPage,
          sort_by: "id",
          order: "desc",
          keyword: this.searchQuery
        };

        const response = await this.$store.dispatch(
          "receiving/getReceivingList",
          params
        );
        this.dataList = response.data?.data || [];
        this.totalProducts = response.data?.page?.total || 0;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi lấy dữ liệu",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      }
    },
    async handleGetSalingList() {
      try {
        const params = {
          inventory_id: this.inventoryInfo?.id,
          page_num: this.currentPage,
          page_size: this.perPage,
          sort_by: "id",
          order: "desc",
          keyword: this.searchQuery,
          status: this.status,
          payment_status: this.payment_status
        };

        const response = await this.$store.dispatch(
          "saling/getSalingList",
          params
        );
        this.dataList = response.data?.data || [];
        this.totalProducts = response.data?.page?.total || 0;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi lấy dữ liệu",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      }
    },
    formatDate(date) {
      return window.moment(date).format("DD/MM/YYYY");
    },
    formatDateTime(date) {
      return window.moment(date).format("HH:mm DD/MM/YYYY");
    },
    getSupplierLabel(data) {
      return data?.supplier_info?.supplier_name;
    },
    getSaleLabel(type) {
      return type === SALE_TYPE.patient ? "Bệnh nhân" : "Ngoài";
    },
    handleChangeInventory(data) {
      this.inventoryInfo = data;
      this.handleGetListData();
    },
    handleChangeStatus(status) {
      this.handleGetSalingList();
      // if (!status) {
      //   this.$router.replace({
      //     query: {
      //       tab: this.$route.query.tab
      //     }
      //   });
      // } else {
      //   this.$router.replace({
      //     query: {
      //       tab: this.$route.query.tab,
      //       status
      //     }
      //   });
      // }
    },
    handleCheckStatus() {
      if (!this.$route.query.status) return;

      this.status = Number(this.$route.query.status);
    },
    handleChangePaymentStatus(status) {
      this.handleGetSalingList();
      // if (status) {
      //   this.$router.replace({
      //     query: {
      //       tab: this.$route.query.tab,
      //       status
      //     }
      //   });
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>